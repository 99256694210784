.switch {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    gap: 1.6rem;

    span {
        z-index: 50;
        color: var(--txt-color);
        cursor: pointer;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
    }

    &__contact {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 94px;
        height: 37px;
        flex: 0 0 auto;
        justify-content: space-between;
        padding: 11px;
        background-color: var(--primary-color);
        border-radius: 3px;
        transition: 1s;

        &-active {
            color: var(--secondary-color) !important;
        }
    }

    &__lever {
        position: absolute;
        z-index: 0;
        top: -1px;
        display: block;
        width: calc(94px / 2);
        height: 60px;
        background-color: var(--btn-secondary-color);
        transition: 0.5s ease-in-out all;
    }

    &__left {
        /*  left: 0; */
        transform: translateX(-30%);
    }

    &__right {
        /*   right: 0; */
        transform: translateX(80%);
    }
}
