.page404 {
    height: calc(100vh - 400px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    padding-top: calc(400px - 300px);

    &__content {
        display: inline-block;
        display: flex;
        min-width: 300px;
        height: 300px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--btn-primary-color);
        gap: 10px;

        h1,
        p {
            color: white;
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: clamp(1.5rem, 2.5vw, 3.5rem);
            text-align: center;
        }

        h1 {
            font-size: 8rem;
            font-weight: bold;
        }

        .loading {
            position: relative;
        }
    }
}
