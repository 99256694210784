.devis {
    &__infos {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(medium) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 44px;
        }

        @include media(large) {
            gap: 0;
        }

        &-provider {
            position: relative;
            width: 100%;
            height: fit-content;
            @include media(medium) {
                display: flex;
                width: 100%;
                
                flex-direction: column;
            }

            @include media(large) {
                min-width: 211px;
                max-width: 300px;
            }

            p {
                display: flex;
                flex-direction: column;
                font-family: Lato, Arial, Helvetica, sans-serif;
                font-size: 1.6rem;
                line-height: 1.5;

                @include media(medium) {
                    display: inline-block;
                }

                @include media(large) {
                    display: block;
                }

                span {
                    display: inline-block;
                    font-family: Baloo, Arial, Helvetica, sans-serif;
                    font-size: 1.7rem;
                    font-weight: 600;
                }
            }

            .provider-add-content {
                display: flex;
                width: 221px;
                height: 45px;
                align-items: center;
                justify-content: center;
                border: none;
                margin: 0 auto;
                background: var(--btn-secondary-color);
                border-radius: 3px;
                color: var(--secondary-color);
                cursor: pointer;
                font-size: 1.5rem;
                gap: 10px;

                @include media(medium) {
                    margin: 0;
                }

                span {
                    display: inline-block;
                    width: fit-content;
                    font-family: Baloo, Arial, Helvetica, sans-serif;
                    font-weight: bold;
                }
            }
        }

        &-name {
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2.4rem;
            font-weight: bold;
        }
    }
}
