.btn {
    /* Button medium */
    min-width: 195px;
    padding: 11px;
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    font-family: Baloo, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: normal;
    font-weight: bold;


    /* Button small */
    &--small {
        min-width: 110px;
        padding: 11px;
        cursor: pointer;

        @include media(large) {
            font-size: 2rem;
        }
    }

    &-secondary-hover {
        &:hover {
            background-color: var(--btn-secondary-color-hover) !important;
            transition: background-color 0.3s ease-in;
        }
    }

    &-primary-hover {
        &:hover {
            background-color: var(--btn-primary-color-hover) !important;
            transition: background-color 0.3s ease-in;
        }
    }

    /* Button large */
}
