.profil {
    @include media(medium) {
        padding: 50px 0;
    }

    &__hero {
        display: flex;
        height: 172px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        color: var(--text-color);
        gap: 23px;

        h1 {
            font-family: baloo, Arial, Helvetica, sans-serif;
            font-size: 4rem;
            font-weight: bold;
        }

        p {
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 20px;
            text-align: center;
        }
    }

    &__switch {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 31px;
        gap: 22px;
    }

    &__switch-disable {
        background: rgb(255 110 108 / 50%);
    }

    &__content {
        display: flex;
        max-width: 670px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        margin: auto;
        background-color: #fff;
        border-radius: 22px;
        box-shadow: 0 8px 24px rgb(0 0 0 / 25%);
        gap: 32px;

        h2 {
            color: var(--text-color);
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2.4rem;
            font-weight: bold;
        }

        &-img {
            display: flex;
            overflow: hidden;
            width: auto;
            width: 120px;
            height: 120px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        form {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 32px;

            input[type="submit"] {
                max-width: 297px;
                padding: 9px;
                border: none;
                margin: auto;
                background-color: var(--btn-primary-color);
                border-radius: 3px;
                color: white;
                cursor: pointer;
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-size: 2rem;
                font-weight: normal;
                font-weight: 600;

                &:hover {
                    background-color: var(--btn-primary-color-hover);
                    box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
                    transition: color 0.4s ease-in;
                    transition: background-color 0.3s ease-in;
                }
            }
        }
    }

    &__input,
    &__inputFocus {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(103 86 140 / 62%);
        background: rgb(255 255 255 / 71%);
        border-radius: 3px;
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;

        label {
            width: 171px;
            color: var(--text-color);
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-weight: 600;
        }

        input,
        label {
            padding: 18px;
            border: none;
        }

        input {
            width: 100%;
            border-radius: 3px;
            color: var(--text-color);
            font-size: 1.4rem;

            &:focus {
                width: calc(100% - 1px);
                padding: 17px;
                outline: none !important;
            }

            &::placeholder {
                color: var(--text-color);
                font-size: 1.4rem;
                text-align: left;
            }
        }
    }

    &__inputFocus {
        label {
            background-color: var(--btn-primary-color-hover);
            color: var(--text-color);
        }

        input:focus {
            border: 1px solid rgb(103 86 140 / 62%);
            outline: none !important;
        }
    }

    &__input-error {
        margin-bottom: 15px;
        color: red;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 13px;
        line-height: 1.5;
    }
}

.profil-center {
    text-align: center;
}
