.contact-form {
    label {
        display: block;
        margin-bottom: 9px;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 2rem;
    }

    input,
    textarea {
        width: 100%;
        max-width: 543px;
        height: 49px;
        padding: 18px 0 18px 19px;
        border: none;
        background-color: var(--primary-color);
        border-radius: 3px;
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
    }

    input::placeholder,
    textarea::placeholder {
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
    }

    textarea {
        height: 134px;
    }

    input[type="submit"] {
        width: fit-content;
        min-width: 110px;
        min-height: 54px;
        padding: 11px;
        background-color: var(--btn-primary-color);
        color: var(--secondary-color);
        cursor: pointer;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: bold;

        &:hover {
            background-color: var(--btn-primary-color-hover);
            transition: background-color 0.3s ease-in;
        }
    }

    &__response {
        padding: 20px;
        background-color: #4f8657;
        border-radius: 3px;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
        font-weight: normal;

        p {
            color: var(--secondary-color);
            line-height: 1.5;
            text-align: center;
        }
    }
}
