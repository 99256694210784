/* .services-content {
    @include media(large) {
        padding-top: 99px;
    }

    h2 {
        margin-bottom: 35px;
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: clamp(2rem, 2.5rem, 3rem);
        font-weight: bold;
        text-align: center;

        @include media(large) {
            margin-bottom: 83px;
            font-size: 48px;
            text-align: center;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 35px;
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;

        @include media(medium) {
            font-size: 2.8rem;
        }

        @include media(large) {
            margin-bottom: 19px;
        }
    }

    :nth-child(2n + 0) {
        h3,
        h4,
        h5,
        h6 {
            color: var(--text-color-secondary);

            @include media(large) {
                margin-bottom: 19px;
            }
        }
    }
}

.services {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    gap: 46px;

    @include media(small) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 89px;
    }

    @include media(large) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 184px;
    }

    &:nth-child(2n + 0) {
        @include media(large) {
            flex-direction: row;
        }
    }

    &__title {
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-weight: bold;

        @include media(large) {
            font-size: 4rem;
        }
    }

    &__text {
        @include media(large) {
            max-width: 569px;
        }

        p {
            margin: 0;
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 1.6rem;
            font-weight: normal;
            line-height: 1.5;

            @include media(small) {
                font-size: 2rem;
                text-align: center;
            }

            @include media(medium) {
                font-size: 2.4rem;
            }
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 44px;

        @include media(medium) {
            width: 267px;
        }

        @include media(large) {
            padding: 0;
            gap: 44px;
        }

        img {
            max-width: 267px;
            height: auto;

            @include media(large) {
                max-width: 601px;
            }
        }
    }

    :nth-child(2) {
        flex-direction: column;
        align-items: center;
    }
}

*/
.services-content {
    max-width: 1920px;
    margin: auto;

    @include media(large) {
        display: flex;
        flex-direction: column;
        padding: 72px 200px;
        gap: 72px;
    }

    img {
        width: 100%;
        margin: auto;
    }

    h2 {
        margin-bottom: 35px;
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: clamp(2rem, 2.5rem, 3rem);
        font-weight: bold;
        text-align: center;

        @include media(large) {
            margin-bottom: 83px;
            font-size: 48px;
            text-align: center;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 35px;
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;

        @include media(medium) {
            font-size: 2.8rem;
        }

        @include media(large) {
            margin-bottom: 19px;
        }
    }

    :nth-child(2n + 0) {
        h3,
        h4,
        h5,
        h6 {
            color: var(--text-color-secondary);

            @include media(large) {
                margin-bottom: 19px;
            }
        }
    }

    &__settings {
        img {
            @include media(large) {
                width: 300px;
                height: 500px;
            }
        }
    }

    &__table {
        img {
            @include media(large) {
                width: 600px;
            }
        }
    }

    &__btn-save {
        img {
            @include media(medium) {
                width: 800px;
                height: 250px;
            }

            @include media(large) {
                width: 750px;
                height: 250px;
            }
        }
    }

    &__table,
    &__style {
        .services {
            @include media(large) {
                flex-direction: row !important;
            }
        }
    }

    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
        gap: 46px;

        @include media(large) {
            flex-direction: row;
            justify-content: space-around;
            gap: 32px;
        }

        &:last-child {
            @include media(large) {
                flex-direction: row-reverse;
            }
        }

        &__title {
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-weight: bold;

            @include media(large) {
                font-size: 4rem;
            }
        }

        &__text {
            @include media(large) {
                max-width: 569px;
            }

            p {
                margin: 0;
                font-family: Lato, Arial, Helvetica, sans-serif;
                font-size: 1.8rem;
                font-weight: normal;
                line-height: 1.5;

                @include media(small) {
                    font-size: 2.2rem;
                    text-align: center;
                }

                @include media(medium) {
                    font-size: 2.4rem;
                }
            }
        }
    }
}
