.modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 50%);

    &__content {
        position: relative;
        max-height: 80vh;
        padding: 20px;
        margin: 0 10px;
        background: var(--secondary-color);
        border-radius: 5px;
        color: var(--text-color);
        overflow-x: hidden;
        overflow-y: auto;

        &-title {
            margin: 10px 0 20px;
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2.4rem;
            font-weight: bold;
            text-align: center;
        }
    }

    &__close {
        position: absolute;
        top: 23px;
        right: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        color: #dc3545;
        cursor: pointer;
        font-size: 3rem;

        &:hover {
            transform: scale(1.1);
            transition: all 0.3s ease-in;
        }
    }
}
