.Toastify__toast {
    font-size: 1.3rem;
}

.check-icon {
    color: #198754;
}

.Toastify__toast-container {
    width: 320px;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3498db;
}

.Toastify__toast--success {
    background: #07bc0c;
}

.Toastify__toast--warning {
    background: #f1c40f;
}

.Toastify__toast--error {
    background: #e74c3c;
}
