.accordion {
    transition: 2s ease-in-out all;

    &__btn {
        width: 100%;
        padding: 18px;
        border: none;
        background-color: var(--btn-secondary-color);
        color: var(--secondary-color);
        cursor: pointer;
        font-size: 1.8rem;
        outline: none;
        text-align: left;
        transition: 0.4s;

        span {
            width: 18px;
            margin-left: 8px;
        }
    }

    &__active,
    &__content:hover {
        background-color: var(--btn-secondary-color-hover);
    }

    &__panel {
        overflow: hidden;

        /*   min-height: 106px; */

        /*     padding-top: 20px; */
        background-color: var(--secondary-color);
        box-shadow: 0 8px 24px rgb(133 149 165 / 20%);
        color: var(--primary-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
        line-height: 1.5;

        p {
            padding: 38px;
            font-size: 1.8rem;
            text-align: center;
        }

        @include media(small) {
            p {
                text-align: left;
            }
        }
    }
}
