.headerPc {
    z-index: 999;
    display: none;

    @include media(medium) {
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100vw;
        justify-content: space-between;
        background-color: white;
        box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
    }

    @media only screen and (min-device-pixel-ratio: 2) {
        padding: 16px 200px;
    }

    &-logo {
        width: 40px;
        cursor: pointer;
    }

    &__content {
        @include media( medium) {
            display: flex;
            width: 100%;
            max-width: 1920px;
            align-items: center;
            justify-content: space-around;
            padding: 13px 0;
            margin: auto;
        }
    }

    &__nav {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-active {
            text-decoration: underline;
        }

        li {
            position: relative;
        }

        &-indicator {
            position: absolute;
            top: -1rem;
            right: -1rem;
            width: 2rem;
            height: 2rem;
            padding: 0.4rem;
            background-color: var(--btn-secondary-color);
            border-radius: 50%;
            color: white;
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 1.3rem;
            text-align: center;
        }
    }

    &__nav-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 33px;
        text-align: center;

        li {
            a,
            button {
                border: none;
                background-color: transparent;
                color: var(--text-color);
                cursor: pointer;
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-size: 16px;
                font-weight: bold;
                text-decoration: none;

                &:hover {
                    color: var(--btn-primary-color);
                    transition: color 0.1s ease-in;
                }
            }

            .btn-primary {
                padding: 11px;
                border: none;
                background-color: var(--btn-primary-color);
                border-radius: 3px;
                color: white;
                font-size: 16px;
                font-weight: bold;

                &:hover {
                    background-color: var(--btn-primary-color-hover);
                    color: white;
                    transition: color 0.1s ease-in;
                }
            }
        }
    }

    &__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        @include media(small) {
            gap: 41px;
        }

        button {
            min-width: 110px;
            padding: 11px;
            border: none;
            background-color: transparent;
            border-radius: 3px;
            color: var(--btn-primary-color);
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
        }

        button:nth-child(1) {
            background-color: var(--btn-secondary-color);
            color: white;

            &:hover {
                background-color: var(--btn-secondary-color-hover);
                transition: color 0.3s ease-in;
            }
        }
    }

    .header_btn-user {
        width: auto;
        height: 43px;
        margin: auto;
        border-radius: 50px;
        object-fit: cover;
    }
}

/* mobile */
.header__mobile {
    @include media(medium) {
        display: none;
    }

    position: sticky;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;

    &-burger {
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: flex-end;
        padding-right: 35px;
        border-bottom: 1px solid var(--primary-color);
        color: var(--text-color);
        font-size: 25px;
    }

    .header__open {
        position: sticky;
        display: flex;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 50px);
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        background-color: white;
        gap: 20px;

        img {
            margin-bottom: 29px;
        }

        &-logo {
            width: 40px;
            cursor: pointer;
        }
    }

    .header__nav {
        &-block {
            display: flex;
            flex-direction: column;
            margin-bottom: 29px;
            gap: 33px;
            text-align: center;

            a,
            button {
                border: none;
                background-color: transparent;
                color: var(--text-color);
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 600;
                text-decoration: none;
            }

            .btn-primary {
                a {
                    color: white;
                }

                padding: 11px;
                border: none;
                background-color: var(--btn-primary-color);
                border-radius: 3px;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .header_btn-right {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 15px;

        @include media(small) {
            gap: 41px;
        }

        button {
            width: auto;
            padding: 11px;
            border: none;
            background-color: var(--btn-primary-color);
            border-radius: 3px;
            color: white;
            font-size: 16px;
            font-weight: 600;
        }

        button:nth-child(1) {
            background-color: var(--btn-secondary-color);
        }

        .header_btn-user {
            width: auto;
            height: 38px;
            margin: auto;
            border-radius: 50px;
            object-fit: cover;
        }
    }
}

.header__avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 50%;
        cursor: pointer;
    }
}
