.forgot-content {
    width: 250px;

    @include media(small) {
        width: 350px;
    }

    @include media(medium) {
        width: 500px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        input {
            width: 100%;
            max-width: 100%;
            height: 49px;
            padding: 18px 0 18px 19px;
            border: none;
            margin-bottom: 10px;
            background-color: var(--primary-color);
            border-radius: 3px;
            color: var(--text-color);
        }

        input::placeholder {
            color: var(--text-color);
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 1.6rem;
        }

        input[type="submit"] {
            @include media(small) {
                max-width: 60%;
            }

            @include media(medium) {
                max-width: 200px;
            }

            min-width: 110px;
            max-width: 100%;
            min-height: 54px;
            padding: 11px;
            background-color: var(--btn-primary-color);
            color: white;
            cursor: pointer;
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2rem;
            font-weight: bold;
            text-align: center;

            &:hover {
                background-color: var(--btn-primary-color-hover);
                transition: background-color 0.3s ease-in;
            }
        }
    }

    .modal__content-title {
        font-size: 2rem;
    }
}
