.rgpd {
    display: flex;
    max-width: 567px;
    flex-direction: column;
    padding-top: 30px;
    gap: 30px;

    p {
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: center;

        button {
            padding: 3px;
            border: none;
            background-color: transparent;
            border-radius: 3px;
            color: #198754;
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2rem;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        button {
            padding: 9px;
            border: none;
            background-color: #db3646;
            border-radius: 3px;
            color: white;
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 2rem;
            font-weight: normal;

            &:nth-child(2n) {
                background-color: #198754;
            }
        }
    }
}
