.contact {
    &__card {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include media(small) {
            flex-direction: row;
            justify-content: center;
            gap: 40px;
        }

        @include media(large) {
            gap: 31px;
        }

        @media (max-width: 413px) {
            flex-direction: column;
        }

        &-content {
            display: flex;
            width: 302px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px;
            margin: auto;
            background-color: white;
            border-radius: 3px;
            box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
            gap: 8px;

            button {
                min-width: 200px;
                padding: 11px;
                font-size: 1.6rem;

                @include media(large) {
                    min-width: 110px;
                    padding: 5px;
                }
            }

            @media (max-width: 374px) {
                width: 280px;
            }

            @include media(small) {
                width: 266px;
                margin: initial;
            }
        }

        p {
            height: 31px;
            color: var(--text-color);
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2.4rem;
            font-weight: 500;
            font-weight: 600;
            line-height: 1.5;
            text-align: center;

            @include media(small) {
                font-size: 2rem;
            }

            @include media(large) {
                font-size: 2rem;
            }
        }

        &-job {
            font-size: 1.8rem !important;
            font-weight: 500 !important;
        }

        &-icons {
            display: flex;
            height: 3rem;
            align-items: center;
            justify-content: center;
            font-size: 2.5rem;
            gap: 10px;

            svg {
                cursor: pointer;

                &:hover {
                    font-size: 3rem;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
}
