$breakpoints:(
    "small":(
        min-width: 576px
    ),
    "medium":(
        min-width: 992px
    ),
    "large":(
        min-width: 1300px
    ),
    "mac-medium": (
        min-width: 1600px
    ),
    "mac-large":(
        min-width: 1800px
    )
);

@mixin media($name) {
    @if map-has-key($breakpoints, $name) {
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    }
}
