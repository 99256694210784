.devis-table {
    border: none;
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 2rem;



    td {
        display: block;
        width: 90%;
        max-width: calc(90vw - 20px);
        padding: 20px;
        margin: auto;
        text-align: right;
        vertical-align: middle;

        @include media(large) {
            display: table-cell;
            width: auto;
            max-width: none;
            max-width: 100px;
            text-align: center;
            vertical-align: middle;
        }

        &::before {
            margin-right: 5px;
            content: attr(data-label);
            float: left;
            font-family: Baloo, Arial, Helvetica, sans-serif;

            @include media(large) {
                content: none;
            }
        }

        &:nth-child(3) {
            text-align: right;

            @include media(large) {
                max-width: 220px;
                text-align: center;
            }
        }
    }

    thead {
        background-color: var(--talbe-header-color);

        tr {
            display: none;

            @include media(large) {
                display: table-row;
            }

            th {
                height: 76px;
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-size: 20px;
                vertical-align: middle;
            }
        }
    }

    tbody {
        display: flex;
        min-width: 100%;
        flex-direction: column;
        padding-bottom: 0;
        box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
        gap: 35px;

        @include media(small) {
            width: 50%;
        }

        @include media(large) {
            display: table-row-group;
            gap: 0;
        }

        tr {
            padding-bottom: 20px;

            .action {
                display: flex;
                max-width: inherit;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                color: #198754;
                cursor: pointer;
                font-size: 40px;
                gap: 10px;
                text-align: center;

                &-delete {
                    color: white !important;
                    font-size: 2rem;

                    @include media(large) {
                        color: #dc3545 !important;
                        font-size: 4rem;
                    }
                }

                &-mobile {
                    width: 212px;
                    padding: 9px;
                    margin: auto;
                    margin-top: 20px;
                    background-color: #dc3545;
                    border-radius: 3px;
                    color: white;
                    font-family: Lato, Arial, Helvetica, sans-serif;
                    font-size: 1.7rem;

                    @include media(large) {
                        display: table-cell;
                        background-color: transparent;
                        font-size: 4rem;
                    }
                }
            }

            .background-mobile {
                display: flex;
                height: 30px;
                align-items: center;
                justify-content: center;
                background-color: var(--primary-color);
                border-radius: 3px;

                @include media(large) {
                    display: table-cell;
                    background-color: transparent;
                }

                .drag-icon {
                    display: inline-block;
                    padding: 5px;
                    border-radius: 3px;
                    color: var(--btn-primary-color);
                    font-size: 3rem;

                    @include media(large) {
                        background-color: transparent;
                    }
                }
            }

            &td {
                height: 64px;
                vertical-align: middle;

                &:focus {
                    padding: 0 5px;
                    border-radius: 3px;
                    outline-color: var(--primary-color);
                    text-align: left;
                }
            }

            &:nth-child(1n+0) {
                background-color: var(--custom-ligne-color);
                color: var(--custom-ligne-text-color);
            }

            &:nth-child(2n +0) {
                background-color: var(--custom-ligne2-color);
                color: var(--custom-ligne2-text-color);
            }
        }
    }
}
