.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;

    @include media(small) {
        padding: 42px 0;
    }

    &__nav-block {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include media(small) {
            flex-direction: row;
        }

        li {
            text-align: center;
        }

        a {
            border: none;
            background-color: transparent;
            color: var(--btn-primary-color);
            cursor: pointer;
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                color: var(--btn-primary-color-hover);
                transition: color 0.1s ease-in;
            }
        }
    }

    &__copy {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        gap: 39px;
        text-align: center;

        p {
            color: var(--text-color);
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-weight: bold;
        }

        :nth-child(2) {
            color: var(--btn-primary-color);
            cursor: pointer;
            text-decoration: underline;

            &:hover {
                box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
                color: var(--btn-primary-color-hover);
                transition: color 0.1s ease-in;
            }
        }

        @include media(small) {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    &__copy::before {
        @include media(small) {
            display: block;
            width: 108px;
            content: "";
        }
    }
}
