.register {
    display: flex;
    width: 325px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    padding: 39px 15px;
    gap: 18px;

    @include media(small) {
        width: 492px;
    }

    @media (max-width: 390px) {
        width: 261px;
    }

    h1 {
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }

    &__content {
        form {
            display: flex;
            flex-direction: column;
            gap: 42px;

            .inputComponent {
                gap: 0;
            }

            label {
                margin-bottom: 11px;
                font-family: Lato, Arial, Helvetica, sans-serif;
                font-size: 1.8rem;
            }

            input[type="submit"] {
                width: 115px;
                margin: auto;
            }

            input[type="checkbox"] {
                width: 24px;
                text-align: center;
            }
        }
    }

    button {
        border: none;
        background-color: transparent;
        font-family: Lato, Arial, Helvetica, sans-serif;
        text-decoration: underline;
    }

    .loading {
        position: relative;
    }
}

.checkboxComponent {
    margin: auto;
}
