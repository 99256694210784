/* TODO:Ajouter un margin bottom entre chaque list */

.table {
    @include media(small) {
        width: 630px;
    }

    @include media(medium) {
        width: 805px;
    }

    @media (max-width: 690px) {
        width: 265px;
    }

    width: 348px;
    margin-top: 25px;
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    text-align: center;

    thead {
        display: none;

        @media (min-width: 692px) {
            display: table-row-group;
        }

        tr {
            height: 35px;
        }
    }

    tbody {
        padding: 5px;
        margin-top: 50px;

        tr {
            display: flex;
            flex-direction: column;
            padding: 5px;
            margin-bottom: 20px;
            box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
            gap: 30px;

            @media (min-width: 692px) {
                display: table-row;
                padding: 0;
                margin-bottom: 0;
                box-shadow: none;
            }
        }

        td {
            position: relative;
            display: flex;
            justify-content: space-between;

            &::before {
                content: attr(data-label);
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-weight: bold;
            }

            @media (min-width: 692px) {
                display: table-cell;

                &::before {
                    content: none;
                }
            }

            .loading {
                position: relative;
                display: inline-block;
                width: auto;
                text-align: center;

                svg {
                    color: white;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__btn {
        display: flex !important;
        align-items: center;
        justify-content: center !important;
        gap: 15px;

        @include media(medium) {
            justify-content: center !important;
            margin-left: 0;
        }

        &-open {
            visibility: hidden;
        }

        button {
            position: relative;
            padding: 9px;
            border: none;
            margin-bottom: 20px;
            background-color: #198754;
            border-radius: 3px;
            color: white;
            cursor: pointer;
            font-weight: 600;

            &:hover {
                background-color: white !important;
                box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
                color: #198754;
                transition: color 0.4s ease-in;
                transition: background-color 0.3s ease-in;
            }

            &:nth-child(2n +0) {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                background-color: #db3646;
                gap: 10px;

                &:hover {
                    background-color: white !important;
                    box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
                    color: #db3646;
                    transition: color 0.4s ease-in;
                    transition: background-color 0.3s ease-in;
                }
            }
        }
    }
}

.tableMobile {
    display: none;

    @include media(medium) {
        display: none;
    }

    @media (min-width: 691px) {
        display: table-cell;
    }

    @media (max-width: 690px) {
        display: none;
    }
}

@keyframes fade-in {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.noQuotation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    gap: 20px;

    button {
        width: 104px;
        height: 35px;
        border: none;
        background-color: #198754;
        border-radius: 3px;
        color: white;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
    }
}
