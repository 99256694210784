.login {
    display: flex;
    width: 325px;
    height: auto;
    flex-direction: column;
    padding: 39px 15px;
    gap: 18px;

    @include media(small) {
        width: 492px;
    }

    @media (max-width: 390px) {
        width: 261px;
    }

    h1 {
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }

    &__content {
        form {
            display: flex;
            flex-direction: column;
            gap: 42px;

            .inputComponent {
                gap: 0;
            }

            label {
                margin-bottom: 11px;
                color: var(--text-color);
                font-size: 1.8rem;
            }

            input[type="submit"] {
                width: 115px;
                margin: auto;
            }
        }
    }

    button {
        border: none;
        background-color: transparent;
        color: var(--btn-secondary-color);
        font-size: 1.8rem;
        line-height: 1.5;
        text-align: center;
        text-decoration: underline;
    }

    .loading {
        position: relative;
    }
}
