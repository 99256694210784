.reset-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 34px;
    gap: 34px;

    @include media (medium) {
        padding: 50px;
    }

    &__block {
        width: 100%;

        @include media (large) {
            width: 30%;
        }
    }

    &__error {
        margin-bottom: 15px;
        color: #bd3947 !important;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5;
    }

    label {
        display: block;
        margin-bottom: 9px;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 2rem;
    }

    input {
        width: 100%;
        height: 49px;
        padding: 18px 0 18px 19px;
        border: none;
        background-color: var(--primary-color);
        border-radius: 3px;
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
    }

    input::placeholder {
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
    }

    input[type="submit"] {
        width: fit-content;
        min-width: 110px;
        min-height: 54px;
        padding: 11px;
        background-color: var(--btn-primary-color);
        color: white;
        cursor: pointer;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: bold;

        &:hover {
            background-color: var(--btn-primary-color-hover);
            transition: background-color 0.3s ease-in;
        }
    }
}
