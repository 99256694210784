@font-face {
    font-display: swap;
    font-family: Baloo;
    font-weight: bold;
    src: url("../font/baloo/Baloo2-Bold.ttf");
}

@font-face {
    font-display: swap;
    font-family: Baloo;
    font-weight: 600;
    src: url("../font/baloo/Baloo2-SemiBold.ttf");
}

@font-face {
    font-display: swap;
    font-family: Baloo;
    font-weight: normal;
    src: url("../font/baloo/Baloo2-Regular.ttf");
}

/* Lato */
@font-face {
    font-display: swap;
    font-family: Lato;
    font-weight: 300;
    src: url("../font/lato/Lato-Light.ttf");
}

@font-face {
    font-display: swap;
    font-family: Lato;
    font-weight: normal;
    src: url("../font/lato/Lato-Regular.ttf");
}

@font-face {
    font-display: swap;
    font-family: Lato;
    font-weight: bold;
    src: url("../font/lato/Lato-Bold.ttf");
}
