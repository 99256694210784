.contact__form {
    form {
        display: flex;
        flex-direction: column;
        gap: 34px;
    }
}

.inputComponent {
    display: flex;
    flex-direction: column;
    gap: 9px;
    text-align: left;

    label {
        margin-bottom: 9px;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 2rem;
    }

    input,
    textarea {
        width: 100%;
        max-width: 543px;
        height: 49px;
        padding: 18px 0 18px 19px;
        border: none;
        background-color: var(--primary-color);
        border-radius: 3px;
        color: var(--text-color);
    }

    input::placeholder,
    textarea::placeholder {
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
    }

    textarea {
        height: 134px;
    }

    input[type="submit"] {
        min-width: 110px;
        min-height: 54px;
        padding: 11px;
        background-color: var(--btn-primary-color);
        color: white;
        cursor: pointer;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: bold;

        &:hover {
            background-color: var(--btn-primary-color-hover);
            transition: background-color 0.3s ease-in;
        }
    }

    &__error {
        margin-bottom: 15px;
        color: #bd3947;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5;
    }
}
