.legal {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.5;

    &__hero {
        display: flex;
        height: 172px;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        color: white;
    }

    h1,
    h2,
    h3 {
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 4rem;
        font-weight: bold;
        text-align: center;
    }

    h1 {
        color: var(--text-color);
        font-size: 2.4rem;

        @include media(small) {
            font-size: 4rem;
        }
    }

    h2,
    h3 {
        font-size: 2.4rem;
    }

    h3 {
        @include media(small) {
            font-size: 2.9rem;
            text-align: left;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 31px;

        @include media(large) {
            padding: 50px 0;
        }
    }

    p {
        &:last-child {
            color: var(--text-color);
            font-weight: bold;
        }
    }
}
