.loading {
    position: absolute;
    width: 100%;

    &__spinner {
        width: 100%;
        height: 100%;
    }

    &__spinner-inner {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__circle {
        margin: auto;
        animation: spinner 1s infinite;
        color: var(--primary-color);
        font-size: 4rem;
        text-align: center;
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
