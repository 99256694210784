.contact {
    @include media(large) {
        padding-bottom: 58px;
    }

    h2 {
        margin-bottom: 39px;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 4rem;
        font-weight: 600;
        text-align: center;
    }

    &__hero {
        display: flex;
        height: 172px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        gap: 23px;

        h1 {
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 4rem;
            font-weight: bold;
            text-align: center;
        }

        p {
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 2.4rem;
            font-weight: normal;
            line-height: 1.5;
            text-align: center;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 44px;

        @include media (large) {
            flex-direction: row;
            padding-top: 73px;
            gap: 126px;
        }

        /*  &-team {

        } */
    }

    &__form {
        width: 100%;
        max-width: 543px;

        .inputComponent input,
        .inputComponent textarea {
            background-color: var(--primary-color);
        }

        @include media(small) {
            margin: auto;
        }

        &-btn {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            gap: 40px;

            @include media (small) {
                justify-content: flex-end;
                gap: 16px;
            }
        }
    }
}
