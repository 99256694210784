.profil__delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    gap: 36px;

    p {
        color: black;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;

        span {
            margin-left: 5px;
            color: #dc3545;
        }
    }

    input {
        width: 290px;
        max-width: 290px;
        padding: 0;

        @include media(small) {
            width: 550px;
            max-width: 582px;
        }

        @include media(medium) {
            width: 582px;
            max-width: 582px;
        }

        &::placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    button {
        width: 104px;
        height: 35px;
        border: none;
        background-color: #dc3545;
        border-radius: 3px;
        color: white;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
    }
}

.profil__btn-delete {
    color: #dc3545;
    cursor: pointer;
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        color: #dc3546da;
    }
}
