.devis {
    display: flex;
    max-width: 1920px;
    flex-direction: column;
    margin: auto;
    gap: 35px;

    &__infos {
        .helement-edit {
            overflow-wrap: anywhere;
        }

        .devis__infos-provider:last-child {
            .provider-add-content {
                margin-left: auto;
            }

            @include media(medium) {
                .small-popin {
                    right: 0;
                    left: unset;
                }
            }
        }
    }

    @include media(small) {
        padding-right: 65px;
        padding-left: 65px;
    }

    @include media(medium) {
        padding: 39px;
    }

    &__btn-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 23px;

        @include media(medium) {
            width: 90%;
            padding: 0;
            margin: auto;
        }



        button {
            min-width: 110px;
            padding: 7px;
            font-size: 1.6rem;
        }
    }

    /* all table */
    &__container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        gap: 68px;

        @include media(small) {
            align-items: unset;
        }

        @include media(large) {
            min-width: 1320px;
            flex-direction: row;
            padding: 0 50px;
        }
    }

    /*  header */
    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 35px;

        @include media(large) {
            flex-direction: row;
            justify-content: space-between;
            margin-left: 78px;
        }

        span {
            position: relative;

            .loading {
                top: 18px;
                left: 3px;
                width: 100%;
                max-width: 82px;
            }
        }

        img {
            width: 100%;
            max-width: 82px;
            height: auto;
        }
    }

    /* containt */
    &__containt {
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
        gap: 35px;

        @include media(mac-medium) {
            min-width: 70%;
        }

        /* ref of client */
        &-ref {
            display: flex;
            flex-direction: column;
            gap: 22px;
            text-align: left;

            p {
                color: var(--text-color);
                font-family: Lato, Arial, Helvetica, sans-serif;
                font-size: 1.6rem;

                &:first-child {
                    color: var(--text-color);
                    font-family: Baloo, Arial, Helvetica, sans-serif;
                    font-size: 2rem;
                    font-weight: bold;
                }
            }

            &-client {
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-size: 2rem;
                font-weight: bold;
            }
        }

        button {
            min-width: 221px;
            padding: 11px;
            margin: auto;
            font-size: 1.6rem;

            @include media(medium) {
                width: 122px;
                margin: 0;
            }
        }
    }

    &__input {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
        gap: 16px;

        input {
            width: 93px;
            height: 37px;
            border: none;
            background: var(--primary-color);
            border-radius: 3px;
            color: var(--text-color);
            cursor: pointer;
            text-align: center;
        }
    }

    &__result {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 35px;
        gap: 35px;

        @include media(medium) {
            align-items: flex-start;
        }

        @include media(large) {
            width: 100%;
            flex-direction: row;
        }

        table {
            width: 100%;
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 2rem;

            @include media(large) {
                width: 408px;
                flex-direction: row;
            }

            tbody {
                tr {
                    border-bottom: 1px solid #00000050;
                    background-color: var(--talbe-header-color);

                    &:last-child {
                        border: none;
                    }

                    td {
                        width: 50%;
                        padding: 17px 0;
                        text-align: center;

                        &:nth-child(2) {
                            background-color: white;
                            text-align: center;
                        }
                    }
                }
            }
        }

        &-tva {
            max-width: 433px;
            height: auto;
            min-height: 98px;
            padding: 11px;
            border: 1px solid #1b1325;
            border-radius: 3px;
            color: var(--text-color);
            font-family: Lato, Arial, Helvetica, sans-serif;
            font-size: 1.5rem;
            line-height: 1.5;
            overflow-wrap: anywhere;

            span {
                font-weight: 600;
            }
        }
    }

    .facture-notif {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 35px;
        margin-bottom: 50px;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 1.8rem;
        font-weight: 600;
        gap: 22px;

        @include media(large) {
            width: 50%;
            flex-direction: row;
            padding-left: 50px;
            font-size: 2rem;
        }

        &__champ {
            display: flex;
            flex-direction: column;
            gap: 22px;
        }
    }

    &__validate {
        margin-top: 35px;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 1.7rem;
        font-weight: 600;

        span {
            line-height: 1.5;
        }
    }

    .helement-edit {
        width: 100%;
        height: auto;
        min-height: 30px;
        padding: 2px 15px;
        margin-bottom: 20px;
        background-color: #efefef;
        border-radius: 3px;
        color: var(--text-color);
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-weight: normal;

        &:focus {
            border: none;
            outline: none;
        }
    }

    .provider-add-icon {
        color: var(--secondary-color);
        font-size: 2rem;
    }

    .drag-icon {
        color: #198754;
        font-size: 2.5rem;
    }

    .small-popin {
        position: absolute;
        z-index: 3;
        bottom: -200px;
        left: calc(50% - 125px);
        width: 250px;
        padding: 20px;
        background-color: var(--secondary-color);
        border-radius: 15px;
        box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
        font-family: Lato, Arial, Helvetica, sans-serif;
        transition: 1s;

        @include media(medium) {
            left: 0;
        }

        &__header {
            width: 100%;

            label {
                display: block;
                width: 200px;
                color: var(--text-color);
                font-family: Baloo, Arial, Helvetica, sans-serif;
                font-size: 1.5rem;
                font-weight: bold;
                line-height: 1.5;
                text-align: center;
            }
        }

        &__item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 8px;
            gap: 10px;

            label {
                font-size: 1.3rem;
            }

            input {
                cursor: pointer;
            }
        }
    }
}

.devis-accordion {
    &__btn {
        width: 100%;
        height: 40px;
        border: none;
        background-color: var(--btn-primary-color);
        color: var(--secondary-color);
        cursor: pointer;
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        font-weight: bold;

        &:hover {
            background-color: var(--btn-primary-color-hover);
        }
    }
}
