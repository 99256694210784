.devis__setings {
    position: sticky;
    top: 115px;
    width: 100%;
    height: fit-content;
    padding-bottom: 20px;
    box-shadow: 0 8px 24px rgb(149 157 165 / 20%);

    @include media(medium) {
        width: 50%;
        margin: auto;
    }

    @media (min-width: 1600px) {
        width: 50%;
    }

    @include media(large) {
        width: fit-content;
        margin: 0;
    }

    &-header {
        display: flex;
        height: 75px;
        align-items: center;
        justify-content: flex-start;
        padding-left: 17px;
        background-color: var(--primary-color);
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2.4rem;
    }

    &-headerSmall {
        height: 30px;
        justify-content: center;
        margin-top: 20px;
        border-radius: 3px;
        font-size: 1.9rem;
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 15px;
        gap: 17px;
    }

    select {
        padding: 9px;
        background-color: var(--btn-primary-color);
        border-radius: 3px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: var(--btn-primary-color-hover);
        }
    }

    &-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 22px;

        button {
            display: flex;
            width: 221px;
            height: 45px;
            align-items: center;
            justify-content: center;
            border: none;
            background: #ff6e6c;
            border-radius: 3px;
            color: white;
            cursor: pointer;
            gap: 10px;

            span {
                display: inline-block;
                width: 110px;
            }

            &:hover {
                background-color: var(--btn-secondary-color-hover);
            }

            @include media(large) {
                width: 336px;
            }
        }
    }
}
