.services-content__pc {
    display: none;

    @include media(medium) {
        display: block;
    }
}

.services-content__mobile {
    @include media(medium) {
        display: none;
    }
}

.home {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    color: white;

    @include media(large) {
        padding: 72px 0;
    }

    &__content {
        @include media(medium) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        display: flex;
        flex-direction: column;
    }

    &__content-chekList {
        display: flex;
        flex-direction: column;
        margin-bottom: 38px;
        gap: 12px;

        p {
            color: var(--text-color);
            font-size: 2.5rem;
            text-align: left;
        }
    }

    &__checkList {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 38px;
        text-align: center;

        @include media(medium) {
            display: block;
            text-align: left;
        }

        h1 {
            margin-bottom: 38px;
            color: var(--text-color);
            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: clamp(2.4rem, 3.5rem, 4.8rem);
            font-weight: bold;
            line-height: normal;

            @include media(large) {
                font-size: 4.1rem;
            }
        }

        li {
            @include media(large) {
                font-size: 2.4rem;
            }

            @include media(small) {
                font-size: 2.5rem;
            }

            font-family: Baloo, Arial, Helvetica, sans-serif;
            font-size: 1.5rem;
            font-weight: normal;
        }
    }

    &__mockup {
        text-align: center;

        img {
            width: auto;
            max-height: 155px;

            @include media(small) {
                max-height: 234px;
            }

            @include media(large) {
                max-height: 333px;
            }
        }
    }
}
