.quotation-mockup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);

    @include media(large) {
        flex-direction: row;
        flex-direction: column;
        padding: 72px 0;
    }

    h1 {
        margin-bottom: 31px;
        color: var(--text-color);
        font-family: Baloo, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;

        @include media(large) {
            margin-bottom: 72px;
            font-size: 4rem;
        }
    }

    &-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--text-color);
        gap: 15px;

        svg {
            z-index: 0;
            transform: rotate(90deg);

            @include media(small) {
                transform: rotate(0);
            }
        }

        @include media(small) {
            flex-direction: column;
            justify-content: center;
        }

        @include media(medium) {
            flex-direction: row;
        }

        @include media(large) {
            align-items: center;
            justify-content: center;
            font-size: 4rem;
            gap: 30px;
        }

        img {
            @media (max-width: 378px) {
                max-width: 260px;
            }

            max-width: 364px;
            height: auto;

            @include media(small) {
                max-width: 455px;
            }

            @include media(medium) {
                max-width: 466px;
            }

            @include media(large) {
                max-width: 560px;
            }
        }
    }
}
