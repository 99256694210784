@media print {
    header,
    .footer,
    .header__open {
        display: none !important;
    }

    .devis {
        display: block !important;
        width: 100%;
        height: 100% !important;
        padding: 0;
        margin: 0;
        box-shadow: none !important;
        font-family: Baloo, Arial, Helvetica, sans-serif;

        &__header {
            flex-direction: row;
            justify-content: space-between;
        }

        &__containt {
            position: absolute !important;
            top: 0 !important;
            bottom: 0 !important;
            display: flex !important;
            width: 100% !important;
            height: 100% !important;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: none !important;
        }

        &__infos p {
            width: auto;
            font-weight: 600;

            span {
                font-size: var(--print-font-size-big);

                &:first-child {
                    display: none;
                }
            }
        }

        &__infos {
            width: 100% !important;
            max-width: 100% !important;
            flex-direction: row !important;
            align-items: flex-start !important;
            justify-content: space-between !important;
            padding: 0 !important;
            margin: 0 !important;
            gap: 0 !important;

            &-provider {
                display: flex;
                max-width: 45% !important;
                flex-direction: column;
                overflow-wrap: anywhere;

                .provider-add-content {
                    display: none;
                }
            }
        }

        &__btn-menu,
        &__setings,
        button,
        .provider-add-icon {
            display: none !important;
        }
    }

    .helement-edit {
        display: contents !important;
        min-height: fit-content !important;
        padding: 0 !important;
        margin-bottom: 0 !important;
    }

    /* global */
    tr {
        display: table-row !important;

        th,
        td {
            display: table-cell !important;
        }

        td {
            text-align: center;
            vertical-align: middle;

            &::before {
                content: "" !important;
            }
        }
    }

    .action,
    .background-mobile {
        display: none !important;

        &-delete,
        &-mobile {
            display: none !important;
        }
    }

    /* tableau */
    .devis-table {
        width: 100%;
        margin-bottom: 10% !important;

        /* header */
        thead {
            display: table-row-group !important;

            th {
                height: 50px !important;
                font-size: var(--print-font-size-big) !important;
            }
        }

        /* body */
        tbody {
            display: table-row-group !important;
            box-shadow: none !important;

            /* tr */
            tr {
                td {
                    width: fit-content;
                    font-size: var(--print-font-size-default) !important;
                    text-align: center !important;
                    vertical-align: middle !important;

                    &:nth-child(3) {
                        max-width: 200px !important;
                        text-align: left !important;
                    }
                }
            }
        }
    }

    .devis__result {
        display: flex !important;
        flex-direction: row !important;

        &-tva {
            width: 40%;
            font-size: var(--print-font-size-default) !important;
        }

        table {
            width: 50%;

            td {
                padding: 5px !important;
                font-size: var(--print-font-size-default) !important;
            }
        }
    }

    .devis__validate {
        position: sticky;
        bottom: 0 !important;
        font-size: var(--print-font-size-default) !important;
    }
}
