:root {
    /* background */
    --primary-color: #d1d1e9;
    --secondary-color: #fffffe;

    /* btn primary */
    --btn-primary-color: #6246ea;
    --btn-primary-color-hover: rgb(98 70 234 / 70%);

    /* btn secondary */
    --btn-secondary-color: #e45858;
    --btn-secondary-color-hover: rgb(228 88 88 / 70%);

    /* text */
    --text-color: #2b2c34;
    --text-color-secondary: #e45858;
    --print-font-size-default: 10pt;
    --print-font-size-big: 12pt;

    /* stroke */
    --stroke-color: #2b2c34;

    h1,
    h2,
    h3 {
        font-family: Baloo, Arial, Helvetica, sans-serif;
    }

    p {
        color: var(--text-color);
    }

    font-display: swap;
    font-size: 62.5%;
}

.padding-content {
    padding: 31px 24px;

    @include media(large) {
        padding: 0;
    }
}

.container {
    width: 100%;
    margin: auto;

    @include media(medium) {
        max-width: 1237px;
    }
}

.container-padding {
    @include media(large) {
        padding-top: 133px;
    }
}
